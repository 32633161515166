<template>
  <div style="">
    <a-card title="报警记录" :bordered="false" style="">
      <div class="table-page-search-wrapper">
        <a-form :form="form" layout="inline">
          <a-row :gutter="[24, 0]">
            <a-col :xxl="8" :sm="24">
              <a-form-item label="选择水厂：">
                <a-cascader
                  v-if="cascaderDefault.length > 0"
                  :fieldNames="{
                    label: 'name',
                    value: 'id',
                    children: 'children'
                  }"
                  :options="tree"
                  placeholder=""
                  :allowClear="false"
                  :defaultValue="cascaderDefault"
                  @change="onCascaderChange"
                />
              </a-form-item>
            </a-col>
            <a-col :xxl="8" :sm="24">
              <a-form-item label="报警类型：">
                <a-select
                  style=""
                  placeholder="全部"
                  v-model="queryParam.type"
                >
                  <a-select-option v-for="item in zhibiaos" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="8" :sm="24">
              <a-form-item no-prop label="监测点">
                <a-select
                  style=""
                  placeholder="请选择"
                  v-model="queryParam.mid"
                >
                  <a-select-option v-for="item in monitors" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!--<a-col :xxl="8" :sm="24">-->
            <!--<a-form-item no-prop label="监测管井">-->
            <!--<a-select-->
            <!--style=""-->
            <!--placeholder="请选择"-->
            <!--v-model="queryParam.groupid"-->
            <!--&gt;-->
            <!--<a-select-option v-for="item in groups" :key="item.id">-->
            <!--{{ item.name }}-->
            <!--</a-select-option>-->
            <!--</a-select>-->
            <!--</a-form-item>-->
            <!--</a-col>-->
            <a-col :xxl="8" :sm="24">
              <a-form-item no-prop label="选择日期">
                <a-range-picker v-model="range" />
              </a-form-item>
            </a-col>

            <a-col :xxl="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery">查询</a-button>
                <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card title="查询结果" :bordered="false" style="margin-top: 5px;">
      <div slot="extra">
        <a-button type="primary" style="margin-left:10px;" @click="exportReport">
        导出数据
        </a-button>
      </div>
      <a-table
        row-key="index"
        :columns="columns"
        :dataSource="data"
        :pagination="pagination"
        :loading="confirmLoading"
        @change="handleTableChange"
      >
        <a slot="leakPercent" slot-scope="text, record">{{ text }}%</a>
        <template slot="index" slot-scope="text, record">
          <div>{{ text }}</div>
        </template>
        <template slot="time" slot-scope="text, record">
          <div>{{ record.start }} - {{ record.end }}</div>
        </template>
          <template slot="status" slot-scope="text, record">
              <a-tag color="red" v-if="record.status === 0">
                  报警
              </a-tag>
              <a-tag color="green" v-else>
                  恢复正常
              </a-tag>
          </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import {
  getAreaParam,
  monitorList,
  groupList,
  pipeQuery,
  pipeQueryExprot,
  waterfactoryTree,
  connections,
  warnZhibiaoList,
  warnPage
} from "@/api/monitor/index";
import { export_json_to_excel } from '@/excel/Export2Excel'


const publicColums = [
  {
    dataIndex: "index",
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" }
  },
  {
    dataIndex: "monitorName",
    title: "监测点",
    align: "center"
  },
  {
    dataIndex: "groupName",
    title: "监测管井",
    align: "center"
  },
  {
    dataIndex: "typeName",
    title: "报警类型",
    align: "center"
  },
  {
    dataIndex: "status",
    title: "报警状态",
    align: "center",
    scopedSlots: {customRender: 'status'}
  },
  {
    dataIndex: "value",
    title: "数值",
    align: "center"
  },
  {
    dataIndex: "descr",
    title: "触发条件",
    align: "center"
  },
  {
    dataIndex: "warntime",
    title: "报警时间",
    align: "center"
  },
  {
    dataIndex: "recoverytime",
    title: "恢复时间",
    align: "center"
  }
];
export default {
  name: "alarm",
  data() {
    return {
      form: this.$form.createForm(this),
      confirmLoading: false,
      columns: publicColums,
      data: [],
      pagination: {
        size: "",
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showLessItems: true,
        pageSizeOptions: ["5", "10", "20", "30", "50"],
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize;
        },
        showTotal: total => `共${total}条`
      },
      tree: [],
      cascaderDefault: [],
      connections: [],
      fromMonitors: [],
      toMonitors: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        orderBy: "",
        wfid: undefined,
        type: 0,
        mid: 0,
        groupid: 0,
        startTime: moment(),
        endTime: moment(),
      },
      years: [2021],
      init: false,
      zhibiaos: [],
      monitors: [],
      groups: [],
      range: [moment(), moment()]
    };
  },
  created() {
    let param = getAreaParam();
    waterfactoryTree(param).then(r => {
      this.tree = [r.data];
      this.cascaderDefault = this.getTreeDefaultValue(this.tree);
      console.log(this.cascaderDefault);
      let wfid = this.cascaderDefault[this.cascaderDefault.length - 1];
      this.queryParam.wfid = wfid;
    });
  },
  mounted() {},
  watch: {
    "queryParam.wfid"(val) {
      console.log("watch queryParam.wfid", val);
      monitorList({ wfid: val }).then(r => {
        this.monitors = r.data;
        this.monitors.unshift({id:0,name:'全部'})
      });
      warnZhibiaoList({wfid:val}).then(r=>{
        this.zhibiaos = r.data
        this.zhibiaos.unshift({id:0,name:'全部'})
      })
      if (!this.init) {
        this.init = true;
        this.fetchList();
      }
    },
    "queryParam.mid"(val) {
      groupList({ mid: val }).then(r => {
        this.groups = r.data;
        this.groups.unshift({ id: 0, name: "全部" });

      });
    },
    "queryParam.timeType"(val) {
      switch (val) {
        case "hour":
          this.queryParam.date = moment();
          break;
        case "day":
          this.queryParam.date = moment();
          break;
        case "month":
          this.queryParam.date = this.years[0];
          break;
      }
    }
  },
  methods: {
    getTreeDefaultValue(tree) {
      let arr = [];
      let first = tree[0];
      arr.push(first.id);
      while (first.children && first.children.length > 0) {
        arr.push(first.children[0].id);
        first = first.children[0];
      }
      return arr;
    },
    fetchList() {
      this.confirmLoading = true;
      const param = Object.assign({}, this.queryParam);
      param.startTime = moment(this.range[0]).format("YYYY-MM-DD");
      param.endTime = moment(this.range[1]).format("YYYY-MM-DD");
      warnPage(param)
        .then(r => {
          const data = r.data;
          // const list = data.list
          // const len = list.length
          // for (let i = 0; i < len; i++) {
          //   list[i].index = i + 1
          // }
          const list = data.list;
          const len = list.length;
          const pageNum = data.pageNum;
          const pageSize = data.pageSize;
          for (let i = 0; i < len; i++) {
            list[i].index = (pageNum-1) * pageSize + i + 1;
            list[i].warntime = moment(list[i].warntime).format("YYYY-MM-DD HH:mm:ss");
            list[i].recoverytime = list[i].recoverytime ? moment(list[i].recoverytime).format("YYYY-MM-DD HH:mm:ss") : '-';
          }
          this.pagination.current = pageNum
          this.pagination.total = data.total
          this.pagination.pageSize = pageSize

          this.data = list;
          this.confirmLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.confirmLoading = false;
        });
    },
    exportReport() {
      const params = Object.assign({}, this.queryParam)
      params.pageNum = 1
      params.pageSize = 1000000
      warnPage(params).then(res => {
        let list = res.data.list
        if(list){
          const len = list.length
          for (let i = 0;i < len;i++) {
            list[i].index = i + 1
            list[i].status = list[i].status === 0 ? '报警' : '恢复正常'
            list[i].warntime = moment(list[i].warntime).format("YYYY-MM-DD HH:mm:ss");
            list[i].recoverytime = list[i].recoverytime ? moment(list[i].recoverytime).format("YYYY-MM-DD HH:mm:ss") : '-';
          }
          const tHeader = ['序号', '监测点', '监测管井', '报警类型','报警状态', '数值', '触发条件', '报警时间', '恢复时间']
          const filterVal = ['index', 'monitorName', 'groupName','typeName', 'status', 'value', 'descr', 'warntime', 'recoverytime']
          const cols = [{wpx:40},{wpx:120},{wpx:150},{wpx:70},{wpx:70},{wpx:50},{wpx:180},{wpx:130},{wpx:130}]
          const data = this.formatJson(filterVal, list)
          export_json_to_excel(tHeader, data, '报警列表',cols)
        }
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    handleTableChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      this.queryParam.pageNum = pagination.current;
      this.queryParam.pageSize = pagination.pageSize;
      this.fetchList();
    },
    onCascaderChange(value) {
      console.log("onCascaderChange", value);
      const wfid = value[value.length - 1];
      this.queryParam.wfid = wfid;
    },
    getZhibiaoTitle(zhibiao) {
      for (let i = 0; i < this.zhibiaos.length; i++) {
        if (zhibiao === this.zhibiaos[i].id) {
          return this.zhibiaos[i].title;
        }
      }
      return "";
    },
    handleQuery() {
      this.fetchList();
    },
    resetQuery() {
      this.fetchList();
    }
  }
};
</script>

<style scoped>
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
